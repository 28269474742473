@import (inline) 'icons.css';

// Placeholders
.placeholdersjs {
  color: #666 !important;
}

// Inline elements
.form-inline .form-group,
.sort-options .form-control {
  display: inline;
  float: left;
}

#findAppointmentsForm {
  .checkbox {
    width: 270px;
  }

  .sort-options {
    label,
    select {
      float: left !important;
    }
    .form-group {
      width: 173px;
    }
    label {
      margin-top: 8px;
    }
    select {
      width: 100px;
    }

    .form-group.last {
      margin-right: -20px;
    }
    display: block !important;
  }

  .change-view {
    margin: -50px 0 0 0;
  }

  .col-xs-10 .form-inline {
    margin: -15px 0 0 0;
  }
}

#detailsSearchAppointments {
  .form-group {
    margin: -1px 0 0 0;
  }
}

// Panels

.panel-schedule {
  time {
    float: left;
    display: inline;
  }
}

// Modal

.modal-dialog {
  width: 448px;
}

.modal-content-padding {
  background: transparent !important;
}

// Forms

#confirmationDateIcon {
  position: relative;
  top: -27px;
}


#confirmationServiceIcon,
#confirmationAvedaServiceIcon {
  position: relative;
  top: -27px;
  left: 89%;
}

#confirmationProviderIcon {
  left: 89%;
  position: relative;
  top: -28px;
}

#confirmationTimeIcon {
  position: relative;
  top: -28px;
  left: 45%;
}
